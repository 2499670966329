import request from '@/utils/request'


// 查询文件列表
export function listFile(query) {
  return request({
    url: '/file/file/list',
    method: 'get',
    params: query
  })
}

// 查询文件分页
export function pageFile(query) {
  return request({
    url: '/file/file/page',
    method: 'get',
    params: query
  })
}

// 查询文件详细
export function getFile(data) {
  return request({
    url: '/file/file/detail',
    method: 'get',
    params: data
  })
}

// 新增文件
export function addFile(data) {
  return request({
    url: '/file/file/add',
    method: 'post',
    data: data
  })
}

// 修改文件
export function updateFile(data) {
  return request({
    url: '/file/file/edit',
    method: 'post',
    data: data
  })
}

// 删除文件
export function delFile(data) {
  return request({
    url: '/file/file/delete',
    method: 'post',
    data: data
  })
}


//批量获取文件
export function getMulFile(data) {
  return request({
    url: '/file/file/getByIds',
    method: 'post',
    data:data
  })
}

//提交报告
export function postMulFile(data) {
  return request({
    url: '/order/orderLanewayHouses/uploadReport',
    method: 'post',
    data:data
  })
}


// 服务端
export function postMulFileF(data) {
  return request({
    url: '/order/order-serve/uploadReport',
    method: 'post',
    data:data
  })
}

//服务订单上传
export function postFile(data) {
  return request({
    url: 'order/orderLanewayHousesServe/uploadReport',
    method: 'post',
    data:data
  })
}